import React, { useContext, useEffect, useState } from 'react';
import { Card, Divider } from '@mui/material';
import { getAllFamilyMemberProfiles } from '../../../actions/matrimonialAction';
import PreviewCardHeader from '../componentUtils/previewCardHeader';
import './preview.css';
import { toast } from 'react-toastify';
import { AppContext } from '../Context';
import { Button } from '@material-ui/core';

const PreviewFamilyMember = ({ matrimonialId: propMatrimonialId, isEditButton }) => {
  const { matrimonialId: contextMatrimonialId } = useContext(AppContext);
  const [memberProfiles, setMemberProfiles] = useState([]);
  const matrimonialId = propMatrimonialId || contextMatrimonialId;

  useEffect(() => {
    fetchData();
  }, [matrimonialId]);

  const fetchData = async () => {
    try {
      const response = await getAllFamilyMemberProfiles(matrimonialId);
      setMemberProfiles(response?.profiles || []);
    } catch (error) {
      toast.error(`Error in fetching Family Members data, ${error}`);
      console.error('Error in fetching Family Members data:', error);
    }
  };

  return (
    <Card>
      <div style={{ margin: '16px' }}>
        <PreviewCardHeader heading={'Family Members Details'} previewStepValue={2} isEditButton={isEditButton} />
        {memberProfiles.map((profile, index) => (
          <div key={index} style={{ borderBottom: '1px solid #ccc', paddingBottom: '10px', marginBottom: '10px' }}>
            <div className="info-wrapper">
              <div className="info-values">
                <div className="label">Name</div>
                <div className="value">
                  {profile?.graph_node_id ? (
                    <Button
                      style={{ textDecoration: 'none', padding: 0, minWidth: 'auto', color: 'black', fontSize: '1rem' }}
                      onClick={() => window.open(`/family-tree?idOfUser=${profile?.graph_node_id}`, '_blank')}
                    >
                      {profile?.metadata?.profile_data?.name ||
                        profile?.independent_profile_data?.name ||
                        'Not Specified'}
                    </Button>
                  ) : (
                    <span style={{ color: 'black', fontSize: '1rem' }}>
                      {profile?.metadata?.profile_data?.name ||
                        profile?.independent_profile_data?.name ||
                        'Not Specified'}
                    </span>
                  )}
                </div>
              </div>
              <div className="info-values">
                <div className="label">Relation</div>
                <div className="value">{profile?.matrimonial_id_data[0]?.relation || 'Not Specified'}</div>
              </div>
              <div className="info-values">
                <div className="label">Occupation</div>
                <div className="value">{profile?.occupation || 'Not Specified'}</div>
              </div>
              <div className="info-values">
                <div className="label">Qualification</div>
                <div className="value">{profile?.qualification || 'Not Specified'}</div>
              </div>
              <div className="info-values">
                <div className="label">Contact Number</div>
                <div className="value">{profile?.contact_number[0] || 'Not Specified'}</div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </Card>
  );
};

export default PreviewFamilyMember;
